<template>
  <section :class="content.class">
    <div class="container">
      <p
        v-if="content.text"
        class="lead"
        v-html="content.text"
      />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
