<template>
  <div :class="content.class">
    <a
      v-if="content.text"
      :href="content.link"
      v-html="content.text"
    />
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
