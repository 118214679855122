<template>
  <ul class="list-block">
    <li
      v-for="(list, index) in listItems"
      :key="index"
      v-html="list"
    />
  </ul>
</template>

<script>
export default {
  props: {
    listItems: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
