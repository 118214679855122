<!-- eslint-disable vue/no-v-html -->
<template>
  <hgroup class="title-block">
    <h6
      v-if="titleBlock.kicker"
      v-html="titleBlock.kicker"
    />
    <h3
      v-if="titleBlock.title"
      v-html="titleBlock.title"
    />
    <h4
      v-if="titleBlock.subtitle"
      v-html="titleBlock.subtitle"
    />
    <p
      v-if="titleBlock.text"
      class="tf-lead"
      v-html="titleBlock.text"
    />
  </hgroup>
</template>

<script>
export default {
  props: {
    titleBlock: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
