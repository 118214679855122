<template>
  <section :class="content.class">
    <div class="callout-container">
      <figure
        v-if="content.image"
        class="media-block"
      >
        <picture>
          <source
            v-if="content.lg_image"
            :srcset="content.lg_image"
            media="(min-width: 1024px)"
          >
          <source
            v-if="content.md_image"
            :srcset="content.md_image"
            media="(min-width: 768px)"
          >
          <img
            alt="Dreamstyle Bathroom"
            :src="content.image"
          >
        </picture>
      </figure>
      <div class="callout-content">
        <TitleBlock
          v-if="content.titleBlock"
          :titleBlock="content.titleBlock"
        />
        <ListBlock
          v-if="content.listItems"
          :listItems="content.listItems"
        />
        <a
          v-if="content.cta_text"
          class="btn"
          :href="content.cta_link"
          v-html="content.cta_text"
        />
      </div>
    </div>
  </section>
</template>

<script>
import TitleBlock from './shared/TitleBlock.vue'
import ListBlock from './shared/ListBlock.vue'

export default {
  components: {
    TitleBlock,
    ListBlock,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
