<template>
  <section :class="content.class">
    <div class="hero-container">
      <figure class="media-block">
        <picture>
          <source
            v-if="content.lg_hero_image"
            :srcset="content.lg_hero_image"
            media="(min-width: 1024px)"
          >
          <source
            v-if="content.md_hero_image"
            :srcset="content.md_hero_image"
            media="(min-width: 768px)"
          >
          <img
            alt="Dreamstyle Bathroom"
            :src="content.sm_hero_image"
          >
        </picture>
      </figure>


      <div class="callout-content">
        <h2
          v-if="content.title"
          v-html="content.title"
        />
        <p
          v-if="content.text"
          v-html="content.text"
        />
        <a
          v-if="content.cta_text"
          class="btn"
          :href="content.cta_link"
          @click="start"
          v-html="content.cta_text"
        />
      </div>
    </div>
  </section>
</template>

<script>

export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    start() {
      this.$store.dispatch('QUIZ_STARTED')
      // `takeQuiz` events defined in `utils/analytics.js`
      window.analytics.tracking.takeQuiz()
    },
  },
}
</script>
