<template>
  <header :class="content.class">
    <div class="container">
      <img
        alt="logo"
        class="header-logo"
        :src="content.logo_path"
      >
      <a
        v-if="content.cta_text"
        :href="content.cta_link"
        class="btn"
        :data-interaction="content.interaction"
        v-html="content.cta_text"
      />
    </div>
  </header>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
