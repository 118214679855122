<template>
  <div class="custom-one-page-bottom">
    <ContactForm />
    <ReviewsSection
      v-if="reviews"
      :content="reviews"
    />
    <DetailsSection
      v-if="hiw"
      :content="hiw"
    />
    <CustomCallout
      v-if="customCalloutBottom"
      :content="customCalloutBottom"
    />
    <PressSection
      v-if="press"
      :content="press"
    />
    <FinalCallout
      v-if="finalCallout"
      :content="finalCallout"
    />
    <DisclaimerSection
      v-if="disclaimer"
      :content="disclaimer"
    />
  </div>
</template>

<script>
import ContactForm from './ContactForm.vue'
import DetailsSection from './DetailsSection.vue'
import CustomCallout from './CustomCallout.vue'
import ReviewsSection from './ReviewsSection.vue'
import PressSection from './PressSection.vue'
import FinalCallout from './FinalCallout.vue'
import DisclaimerSection from './DisclaimerSection.vue'

export default {
  components: {
    ContactForm,
    DetailsSection,
    CustomCallout,
    ReviewsSection,
    PressSection,
    FinalCallout,
    DisclaimerSection,
  },
  props: {
    customCalloutBottom: {
      type: Object,
      default: () => {},
    },
    hiw: {
      type: Object,
      default: () => {},
    },
    reviews: {
      type: Object,
      default: () => {},
    },
    press: {
      type: Object,
      default: () => {},
    },
    finalCallout: {
      type: Object,
      default: () => {},
    },
    disclaimer: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
