<template>
  <footer class="quiz-footer">
    <div class="footer-meta">
      <p class="footer-copyright">
        &copy; {{ currentYear }}
      </p>
      <ul class="footer-links">
        <li>
          <a
            target="_blank"
            href="https://dreamstyleshowers.com/privacy_policy/"
          >Privacy Policy and Terms of Use</a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
  },
}
</script>
