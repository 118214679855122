<template>
  <div v-if="quizData.isCustomOnePage">
    <ThesisForm>
      <template #headerSlot>
        <div class="sticky-top">
          <CustomLinkbar
            v-if="quizData.linkbar"
            :content="quizData.linkbar"
          />
          <CustomHeader
            v-if="quizData.header"
            :content="quizData.header"
          />
        </div>
      </template>
      <template #onePageTop>
        <CustomIntro
          v-if="quizData.intro"
          :content="quizData.intro"
        />
        <CustomCallout
          v-if="quizData.customCalloutTop"
          :content="quizData.customCalloutTop"
        />
      </template>
      <template #onePageIntro>
        <div />
      </template>
      <template #onePageBottom>
        <CustomOnePageBottom
          :customCalloutBottom="quizData.customCalloutBottom"
          :hiw="quizData.hiw"
          :reviews="quizData.reviews"
          :press="quizData.press"
          :finalCallout="quizData.finalCallout"
          :disclaimer="quizData.disclaimer"
        />
      </template>
      <template #footerSlot>
        <Footer />
      </template>
    </ThesisForm>
  </div>
  <div v-else>
    <ThesisForm />
  </div>
</template>

<style>
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 10;
}
</style>

<script>
import CustomLinkbar from './components/CustomLinkbar.vue'
import CustomHeader from './components/CustomHeader.vue'
import CustomIntro from './components/CustomIntro.vue'
import PressSection from './components/PressSection.vue'
import CustomCallout from './components/CustomCallout.vue'
import CustomOnePageBottom from './components/CustomOnePageBottom.vue'
import Footer from './components/Footer.vue'

export default {
  components: {
    CustomLinkbar,
    CustomHeader,
    CustomIntro,
    PressSection,
    CustomCallout,
    CustomOnePageBottom,
    Footer,
  },
  computed: {
    quizData() {
      return this.$store.getters.QUIZDATA
    },
  },
  created() {
    this.$store.dispatch('INITIALIZE_STORE', this.$quizData)
    this.$store.dispatch('/results/INITIALIZE_RESULTS', this.$quizData.sequences)
  },
}
</script>
