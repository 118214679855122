<template>
  <section :class="content.class">
    <div class="container">
      <img
        v-if="content.rating_image"
        :alt="content.rating_image_alt"
        :src="content.rating_image"
      >
      <h2
        v-if="content.title"
        v-html="content.title"
      />

      <div
        v-if="content.logos"
        class="press-content"
      >
        <img
          v-for="(logo, index ) in content.logos"
          :key="`k-${index}`"
          :src="logo.image"
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
