<template>
  <section
    id="sign-up-form"
    class="contact-form"
  >
    <div class="container">
      <div class="title-block">
        <h2 v-html="content.title" />
      </div>
      <form @submit.prevent>
        <div class="form-content">
          <div class="form-group">
            <label for="firstName">First Name</label>
            <input
              id="firstName"
              v-model="firstName"
              type="text"
              name="firstname"
              placeholder="First Name *"
              required
            >
          </div>
          <div class="form-group">
            <label for="lastName">Last Name</label>
            <input
              id="lastName"
              v-model="lastName"
              type="text"
              name="lastname"
              placeholder="Last Name *"
              required
            >
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input
              id="email"
              v-model="email"
              type="email"
              name="email"
              placeholder="Email *"
              required
            >
          </div>
          <div class="form-group">
            <label for="zip">Zip Code</label>
            <input
              id="zip"
              v-model="zip"
              type="number"
              name="zip"
              placeholder="Zip Code *"
              pattern="[0-9]*"
              required
            >
          </div>
          <div class="form-group">
            <label for="phone">Phone Number</label>
            <input
              id="phone"
              v-model="phone"
              type="tel"
              name="phone1"
              placeholder="Phone Number *"
              title="(123-456-7890)"
              pattern="\d{3}[\-]\d{3}[\-]\d{4}"
              required
              @input="maskPhoneNumber"
            >
          </div>
        </div>
        <p class="form-note">
          * Required field
        </p>
        <p
          v-if="formError"
          class="form-error"
        >
          Please answer all quiz questions and enter the required information.
        </p>
        <button
          type="submit"
          @click="submitForm"
        >
          Submit
        </button>
      </form>
      <div class="cta-block">
        <div class="cta-wrapper">
          <a
            href="tel:+18773374116"
            class="btn"
            data-interaction="phone-call"
          >
            Or call us at 877-337-4116
          </a>
          <small class="cta-fine-print">
            By submitting this request, you consent to receive calls and text messaging from Dreamstyle Remodeling about its products and services at the phone number provided even if your number is on any federal, state, or local do not call list. Your consent to this agreement is not required to purchase products or services. You may revoke this consent at any time by contacting Dreamstyle at info@dreamstyleus.com.
          </small>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      zip: '',
      phone: '',
      formError: false,
    }
  },
  computed: {
    formData() {
      return {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        zip: this.zip,
        phone: this.phone,
      }
    },
    content() {
      return this.$store.getters.QUIZDATA.contactForm
    },
    onePageValid() {
      return this.$store.getters.ONE_PAGE_VALID
    },
    isOnePage() {
      return this.$store.getters.QUIZDATA.one_page
    },
    multiPageQuizCompleted() {
      return this.$store.getters.QUIZ_COMPLETED
    },
  },
  methods: {
    maskPhoneNumber() {
      const phoneInput = this.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.phone = !phoneInput[2] ? phoneInput[1] : `${phoneInput[1]}-${phoneInput[2]}${phoneInput[3] ? `-${phoneInput[3]}` : ''}`
    },
    isValidEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    submitForm() {
      // eslint-disable-next-line no-undef
      if (!this.isFormCompleted()) {
        this.formError = true
        return null
      }

      if (this.onePageValid || this.multiPageQuizCompleted) {
        // `handleSubmit` is defined in `contact_form.js`
        return handleSubmit(this.formData)
      }

      return null
    },

    isFormCompleted() {
      const {
        firstName,
        lastName,
        email,
        zip,
        phone,
      } = this.formData
      if (!firstName.length) {
        return false
      }
      if (!lastName.length) {
        return false
      }
      if (!email.length || !this.isValidEmail(email)) {
        return false
      }
      if (!zip.length) {
        return false
      }
      if (!phone.length || phone.length !== 12) {
        return false
      }
      if (this.isOnePage && !this.onePageValid) {
        return false
      }

      return true
    },
  },
}
</script>
