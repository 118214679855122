<template>
  <section :class="content.class">
    <div class="container">
      <TitleBlock
        v-if="content.titleBlock"
        :titleBlock="content.titleBlock"
      />
      <div
        class="reviews-content grid"
        :class="[
          { 'is-carousel-on-mobile': content.carousel },
          `columns-${content.items.length}`
        ]"
      >
        <div
          v-for="(review, index ) in content.items"
          :key="`k-${index}`"
          class="review"
        >
          <figure class="media-block image-block">
            <img
              v-if="review.quote_image"
              :src="review.quote_image"
            >
          </figure>
          <blockquote class="quote-block ">
            <p
              v-if="review.quote_text"
              class="quote-body"
              v-html="review.quote_text"
            />
            <small
              v-if="review.quote_attribution"
              class="quote-attribution"
              v-html="review.quote_attribution"
            />
          </blockquote>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TitleBlock from './shared/TitleBlock.vue'

export default {
  components: {
    TitleBlock,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    if (this.content.carousel) this.initializeCarousel()
  },
  methods: {
    initializeCarousel() {
      const { content } = this
      $('.reviews-content.is-carousel-on-mobile').each(function () {
        const slider = $(this)
        if ($(this).replaceWithSVG) {
          slider.bind('DOMNodeInserted', () => {
            slider.find('[src$=".svg"]').each(function () {
              $(this).replaceWithSVG()
            })
          })
        }
        slider.slick({
          mobileFirst: true,
          responsive: content.responsive_settings || [
            {
              breakpoint: 0,
              settings: {
                arrows: false,
                dots: true,
                slidesToScroll: 1,
                slidesToShow: 1,
              },
            },
          ],
        })
      })
    },
  },
}
</script>
