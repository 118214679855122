<template>
  <section :class="content.class">
    <div class="container">
      <TitleBlock
        v-if="content.titleBlock"
        :titleBlock="content.titleBlock"
      />
      <div class="content-block ">
        <div class="cta-block ">
          <a
            v-if="content.first_cta_text"
            :href="content.first_cta_link"
            class="btn"
            v-html="content.first_cta_text"
          />
        </div>
      </div>
      <div class="cta-block ">
        <a
          v-if="content.second_cta_text"
          :href="content.second_cta_link"
          class="btn"
          :data-interaction="content.second_cta_action"
          v-html="content.second_cta_text"
        />
      </div>
    </div>
  </section>
</template>

<script>
import TitleBlock from './shared/TitleBlock.vue'

export default {
  components: {
    TitleBlock,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
