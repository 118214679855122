<template>
  <section :class="content.class">
    <div class="container">
      <TitleBlock
        v-if="content.titleBlock"
        :titleBlock="content.titleBlock"
      />

      <div class="details-content grid">
        <div
          v-for="(feature, index ) in content.items"
          :key="`k-${index}`"
          class="detail"
        >
          <figure v-if="feature.image">
            <img :src="feature.image">
            <figcaption
              v-if="feature.imageCaption"
              v-html="feature.imageCaption"
            />
          </figure>
          <div class="detail-info">
            <h2
              v-if="feature.title"
              class="title"
              v-html="feature.title"
            />
            <p
              v-if="feature.text"
              class="lead"
              v-html="feature.text"
            />
          </div>
        </div>
      </div>
      <a
        v-if="content.ctaText && content.ctaLink"
        class="btn"
        :href="content.ctaLink"
        v-html="content.ctaText"
      />
    </div>
  </section>
</template>

<script>
import TitleBlock from './shared/TitleBlock.vue'

export default {
  components: {
    TitleBlock,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
